<template>
  <div>
    <div v-if="this.$route.name === 'Employee'">
      <Employee />
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import Employee from "@/views/companyApp/employee/Employee.vue";

export default {
  name: 'EmployeeParent',
  components: {
    Employee
  }
}
</script>
